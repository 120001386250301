import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';

import './index.scss';

const ImageSlider = ({ alboms }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [slidesShow, setSlidesShow] = useState(1);

  const firstThreeAlboms = alboms.results.slice(0, 5);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setSlidesShow(1);
    } else {
      setSlidesShow(2);
    }
  }, [isMobile]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesShow,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
  };

  return (
    <div className='Slider'>
      <p>Новинки</p>
      <Slider {...settings}>
        {firstThreeAlboms.reverse().map((albom) => (
          <Link to={`/albom/${albom.id}`} key={albom.id}>
            <li className='Sounds_Cart' key={albom.id}>
              <img className='Sounds_Cart_Img' key={albom.id} src={albom.photo} alt={albom.id} />
              <div className='clider_body'>
                <div className='Title'>{albom.title}</div>
                <div className='Performer'>{albom.artist.title}</div>
                <div>Кількість композицій: {albom.trecks.length}</div>
                {albom.discount > 0 ? (
                  <>
                    <div className='Discount'>-{albom.discount}%</div>
                    <div className='Price'>
                      <div className='OldPrice'>{albom.price}$</div>
                      <div className='TotalPrice'>{albom.total_price}$</div>
                    </div>
                  </>
                ) : (
                  <div className='Price'>
                    <div className='TotalPrice'>{albom.total_price}$</div>
                  </div>
                )}
              </div>
            </li>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
