import { createContext, useState, useEffect, useCallback } from 'react';
import { axiosUrl } from '../api/axios';

const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [alboms, setAlboms] = useState();
    const [allalboms, setAllAlboms] = useState();
    const [ganres, setGanres] = useState();
    const [discounts, setDiscont] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [lengthGoods, setLengthGoods] = useState([]);
    const [searchTrue, setSearchTrue] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isOpenBasket, setIsOpenBasket] = useState(false);
    const [corectTrack, setCorectTrack] = useState();
    const [page, setPage] = useState(localStorage.getItem('currentPage') ? localStorage.getItem('currentPage') : 1);

    const fetchData = useCallback(async () => {
      try {
          const response = await axiosUrl.get(`alboms/?page=${page}`);
          setAlboms(response.data);
          setLoading(false); // Позначаємо, що завантаження завершено
      } catch (error) {
          console.log(error);
          setLoading(false); // Позначаємо, що завантаження завершено, навіть якщо сталася помилка
      }
      try {
          const allresponse = await axiosUrl.get(`allalboms/`);
          setAllAlboms(allresponse.data);
          setDiscont(allresponse.data.results.filter(albom => albom.discount > 0)); // відбираємо альбоми зі знижкою
          setLoading(false); // Позначаємо, що завантаження завершено
      } catch (error) {
          console.log(error);
          setLoading(false); // Позначаємо, що завантаження завершено, навіть якщо сталася помилка
      }
      try {
          const ganreResponse = await axiosUrl.get(`ganres/`);
          setGanres(ganreResponse.data);
          setLoading(false); // Позначаємо, що завантаження завершено
      } catch (error) {
          console.log(error);
          setLoading(false); // Позначаємо, що завантаження завершено, навіть якщо сталася помилка
      }
  }, [page]);

      useEffect (() => {
        fetchData();
      }, [page, fetchData]);

    //add ratingAlbom
    const ratingAlbom = (item) => {
      axiosUrl.get(`rating/${item}/`)
      .then(()=> {
        fetchData();
      })
      .catch(error => {
        console.log(error)
      });
    }

    const ganrAlbom = (item) => {
      setAlboms('')
      axiosUrl.get(`ganre/${item}/`)
      .then(response => {
        setAlboms(response.data);
        setLoading(false); // Позначаємо, що завантаження завершено
      })
      .catch(error => {
        console.log(error)
      });
    }

    //Search function from artist and albom
    const serachData = (item) => {
      axiosUrl.get(`alboms/?search=${item}`)
        .then(response => {
          setAlboms(response.data);
        })
      .catch(error => {
        console.log(error)
      });
    }

    const contextData = {
      alboms:alboms,
      discounts:discounts,
      loading:loading,
      ratingAlbom:ratingAlbom,
      serachData:serachData,
      // search:search,
      setPage:setPage,
      page:page,
      allalboms:allalboms,
      ganres:ganres,
      ganrAlbom:ganrAlbom,
      setSearchTrue:setSearchTrue,
      searchTrue:searchTrue,
      setIsPlaying:setIsPlaying,
      isPlaying:isPlaying,
      corectTrack:corectTrack,
      setCorectTrack:setCorectTrack,
      setIsOpenBasket:setIsOpenBasket,
      isOpenBasket:isOpenBasket,
    }

  return (
    <DataContext.Provider value={contextData}>
        {children}
    </DataContext.Provider>
  )
}

export { DataContext, DataProvider };