import React from 'react'

import './index.scss'

const About = () => {
  return (
    <main className='About'>
    <h1 className='text'>Вітаємо на SmollStore!</h1>

      <article>
        <img className='img_auto' src="/static/images/about3.jpg" alt="foto" />
        <h2 className='text'>Ми - команда ентузіастів музики, які об'єдналися, щоб створити цей простір для всіх, хто любить музику так само, як і ми.
          SmollStore - це ваша музична платформа, де ви можете насолоджуватися музикою,
           знаходити нових виконавців та ділитися своїми улюбленими треками.
        </h2>
      </article>
      <article className='container'>
        <ul>
        <h1 className='text'>Що ми пропонуємо?</h1>
        
          <li>
            <h2 className='text'>
              Слухати та Завантажувати:
            </h2>
            <div className='container'>
              <h3 className='text'>
                З нашим сайтом ви можете не лише слухати улюблені треки, але і завантажувати їх для подальшого прослуховування офлайн.
                Наша колекція охоплює широкий спектр жанрів, щоб задовольнити різноманітні смаки.
              </h3>   
              <img className='img_size_300' src="/static/images/about1.jpg" alt="foto" />           
            </div>
          </li>
          <li>
            <h2 className='text'>
            Артисти та Альбоми:
            </h2>
            <div className='container'>
              <img className='img_size_300' src="/static/images/about6.jpg" alt="foto" />
              <h3 className='text'>
              Ознайомтеся з нашою базою даних виконавців та альбомів, де ви знайдете не тільки їхню музику, але й цікаві факти та історії.
              </h3>              
            </div>
          </li>
          <li>
            <h2 className='text'>Радіостанції:</h2>
            <div className='container'>
              <h3 className='text'>
              Ви можете вибрати з 10 різних радіостанцій, щоб насолоджуватися безперервним стрімінгом різних музичних напрямків.
              </h3> 
              <img className='img_size_300' src="/static/images/radio.png" alt="foto" />                           
            </div>
          </li>
          <li>
            <h2 className='text'>Плеєр:</h2>
            <div className='container'>
              <img className='img_size_300' src="/static/images/player.jpg" alt="foto" />
              <h3 className='text'>Наш вбудований плеєр дозволяє вам слухати ваші улюблені треки безпосередньо на сайті.
              Зручно, просто, і завжди доступно.</h3>
            </div>
          </li>
        </ul>
      </article>
      <article>
        <h1 className='text'>Наша мета</h1>
        <img className='img_size_500' src="/static/images/about2.jpg" alt="foto" />
        <h2 className='text'>
        Ми прагнемо зробити SmollStore місцем, де кожен може знайти щось особливе для себе. Незалежно від того, чи ви шукаєте нові релізи,
         вивчаєте історію виконавців або просто насолоджуєтеся музикою під час робочого дня, ми створили цей ресурс для вас.
        </h2>
        <img className='img_size_500' src="/static/images/about5.jpg" alt="foto" />
        <h2 className='text'>Долучайтеся до нас і діліться своєю музичною страстю!</h2>
        <h2 className='text'>SmollStore - Ваш простір для музичного відкриття.</h2>
      </article>
    </main>
  )
}

export default About