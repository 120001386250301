import React, { useContext, useState, useEffect } from 'react';
import ImageSlider from '../Components/Slider/Slider';
import DiscontSlider from '../Components/Slider discount/Slider';
import Heard from '../Components/Heard/Heard';
import { Link as NavLink } from 'react-router-dom';
import { DataContext } from '../data/DataContext';
import './index.scss';
import { Pagination, PaginationItem } from '@mui/material';

const Home = () => {
  const {discounts, loading, alboms, setPage, allalboms, page, ganres, ganrAlbom, searchTrue } = useContext(DataContext);
  const [displayData, setDisplayData] = useState([]);
  const [count, setCount] = useState(0);
  const [rating, setRating] = useState();
  const [ganre, setGanre] = useState();

  const changePage = (newPage, search) => {
    setPage(newPage);
    localStorage.setItem('currentPage', newPage.toString());
  };

  useEffect(() => {
    if (allalboms && allalboms.results && Array.isArray(allalboms.results)) {
        const ratings = allalboms.results.map((album) => ({
          title: album.title,
          rating: album.albomRating === null ? 0 : album.albomRating.rating,
          id: album.id
        }));
        // Сортування за рейтингом
        const sortedRatings = ratings.sort((a, b) => b.rating - a.rating);
        setRating(sortedRatings.slice(0, 10));
      }
    if (alboms && alboms.results.length >= 0) {
      setDisplayData([...alboms.results].reverse());
      setCount(alboms.total_pages)
    }
    if (ganres && ganres.results && Array.isArray(ganres.results)) {
      setGanre(ganres.results)
    }
  }, [page, alboms, allalboms, ganres]);

  if (loading) {
    // Відображення загрузки, поки дані завантажуються
    return <main className='Home'>
      <div>Loading...</div>
    </main>;
  }

  return (
    <main className='Home_main'>
      <aside className='Home_aside'>
        <ul className='Home_aside_header'>ПОПУЛЯРНІ АЛЬБОМИ
        {rating ? (rating.map((rating) => (
          <li className='li_home' key={rating.id}>
            <NavLink to={`/albom/${rating.id}`} key={rating.id }>
              <h5 className='Home_aside_main_left'>{rating.title}</h5>  
            </NavLink>
            <div className='Home_aside_main_right'>{rating.rating === null ? ('0') : rating.rating}</div>          
          </li>
        ))) : (<div key='{rating.id}'>Loading...</div>)}
        </ul>
        <ul className='Home_aside_header'>ПО ЖАНРАМ
        {ganre ? (ganre.map((ganr) => (
          <li className='li_home' key={ganr.id}>
              <h5 className='Home_aside_main_left' onClick={() => ganrAlbom(ganr.id)}>{ganr.title}</h5>  
          </li>
        ))) : (<div key='{rating.id}'>Loading...</div>)}
        </ul>
      </aside>
      <main className='Home'>
      {!searchTrue && (allalboms ? <ImageSlider alboms={allalboms}/> : <ImageSlider alboms={alboms}/>)}
      <ul>
        {displayData.map((albom) => (
          <div key={albom.id}>
            <NavLink to={`/albom/${albom.id}`}>
              <li className='Sounds_Cart'>
                <img className='Sounds_Cart_Img' src={albom.photo} alt={albom.id} />
                <div className='Title'>{albom.title}</div>
                <div className='Performer'>{albom.artist.title}</div>
                {albom.discount > 0 ? (
                  <>
                    <div className='Discount'>-{albom.discount}%</div>
                    <div className='Price'>
                      <div className='OldPrice'>{albom.price}$</div>
                      <div className='TotalPrice'>{albom.total_price}$</div>
                    </div>
                  </>
                ) : (
                  <div className='Price'>
                    <div className='TotalPrice'>{albom.total_price}$</div>
                  </div>
                )}
              </li>
            </NavLink>
            <Heard idTrack={albom.id} />
          </div>
        ))}
      </ul>

      <div className='Pagination'>
        { !!count && (
          <Pagination 
            count={count}
            page={page}
            // onChange={handleChangePage}
            onChange={(_, num) => changePage(num)}
            showFirstButton
            showLastButton
            size="small"
            renderItem={(item) => (
              <PaginationItem 
                  component={NavLink}
                  {...item}
              />
            )}
          />
        )}
      </div>
      {!searchTrue && <DiscontSlider discounts={discounts}/>}        
      </main>
    </main>
  );
};

export default Home;
