import React, { createContext, useEffect, useState, useCallback } from 'react'
import jwt_decode from "jwt-decode";
import { axiosUrl } from '../api/axios';
import { useNavigate  } from 'react-router-dom';

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [ user, setUser ] = useState(() => localStorage.getItem('accessToken') ? jwt_decode(localStorage.getItem('accessToken')) : null);
  const [ accessToken, setAccessToken ] = useState(() => localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null);
  const [ refreshToken, setRefreshToken ] = useState(() => localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken') : null);
  const [ err, setErr ] = useState(null);
  const [ userProf, setUserProf ] = useState(null);
  const [ isBuy, setIsBuy ] = useState(false)
  const navigate = useNavigate();

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosUrl.post(`api/token/`,{
        username: e.target.username.value,  // Додаємо користувача
        password: e.target.password.value     // Додаємо пароль
      })
      setErr(null)
      setAccessToken(response.data.access)
      setRefreshToken(response.data.refresh)
      setUser(jwt_decode(response.data.access))
      localStorage.setItem('accessToken', response.data.access)
      localStorage.setItem('refreshToken', response.data.refresh)
      window.location.reload();
    } catch (error) {
      setErr('Не знайдено жодного активного облікового запису з указаними обліковими даними')
    }
  }

  const userProfile = async () => {
    try {
      const responce = await axiosUrl.get(`cabinet/${user.user_id}/`)
      setUserProf(responce.data)
      // console.log(responce.data)
    } catch (error) {
      console.log(error)
    }      
  }

  const logoutUser = useCallback(async () => {
    navigate('/');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('currentPage');
    setUser(null);
    window.location.reload();
  }, [navigate])

  const updateToken = useCallback( async () => {
    try {
      const response = await axiosUrl.post(`api/token/refresh/`,{
        refresh:refreshToken,  // Відправляємо токен
      })
      setErr(null)
      setAccessToken(response.data.access)
      setRefreshToken(response.data.refresh)
      setUser(jwt_decode(response.data.access))
      localStorage.setItem('accessToken', response.data.access)
      localStorage.setItem('refreshToken', response.data.refresh)
    } catch (error) {
        setErr(error.response.data.detail)
        logoutUser()
    }
  },[refreshToken, logoutUser])

  const userAddTracks = (favorite_songs) => {
    const formData = new FormData();
    formData.append('favorite_songs', favorite_songs);
    formData.append('user', user.user_id);
    
    axiosUrl.get(`addtrack/${user.user_id}/${favorite_songs}/`)
    .then(response => {
      console.log('favorite_songs uploaded:', response.data);
    })
    .catch(error => {
        console.error('Error uploading favorite_songs:', error);
    });
    // window.location.reload();
  };

  const userDeleteTracks = (favorite_songs) => {
    const formData = new FormData();
    formData.append('favorite_songs', favorite_songs);
    formData.append('user', user.user_id);
    
    axiosUrl.get(`deltrack/${user.user_id}/${favorite_songs}/`)
    .then(response => {
      console.log('favorite_songs deleted:', response.data);
    })
    .catch(error => {
        console.error('Error deleted favorite_songs:', error);
    });
    window.location.reload();
  };

  const userAddAlboms = (favorite_albom) => {
    const formData = new FormData();
    formData.append('favorite_alboms', favorite_albom);
    formData.append('user', user.user_id);
    
    axiosUrl.get(`addalbom/${user.user_id}/${favorite_albom}/`)
    .then(response => {
      console.log('favorite_songs uploaded:', response.data);
      setIsBuy(true)
    })
    .catch(error => {
        console.error('Error uploading favorite_songs:', error);
    });
    // window.location.reload();
  };

  const userDeleteAlboms = (favorite_albom) => {
    const formData = new FormData();
    formData.append('favorite_alboms', favorite_albom);
    formData.append('user', user.user_id);
    
    axiosUrl.get(`delalbom/${user.user_id}/${favorite_albom}/`)
    .then(response => {
      console.log('favorite_songs deleted:', response.data);
      setIsBuy(true)
    })
    .catch(error => {
        console.error('Error deleted favorite_songs:', error);
    });
    // window.location.reload();
  };

  useEffect(() => {
    if (accessToken) {
      let interval = setInterval(() => {
        updateToken();
      }, 1000 * 60 * 5);
  
      return () => clearInterval(interval);
    }
  }, [accessToken, updateToken]);

  const contextData = {
    loginUser:loginUser,
    err:err,
    authToken:accessToken,
    user:user,
    logoutUser:logoutUser,
    updateToken:updateToken,
    userProfile:userProfile,
    userProf:userProf,
    userAddTracks:userAddTracks,
    userDeleteTracks:userDeleteTracks,
    userAddAlboms:userAddAlboms,
    userDeleteAlboms:userDeleteAlboms,
    setIsBuy:setIsBuy,
    isBuy:isBuy
  }

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  )
}