import React, { useState } from 'react';
import { axiosUrl } from '../../api/axios';
import './index.scss';

const RegistrationModal = ({ toggleModal, registrationModal }) => {
    const [name, setName] = useState('');
    const [pass1, setPass1] = useState('');
    const [pass2, setPass2] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [restpons, setRespons] = useState('');

    const handleFormSubmit = (event) => {
        event.preventDefault(); // Prevents the default form submission behavior
        if (pass1 === pass2) {
            setError('')
            axiosUrl.post(`registration/`, {
                name: name,
                password: pass1,
                email: email
            })
            .then(response => {
                if (response.data.registrationRespons){
                    setRespons(`Вітаємо ${response.data.registrationRespons.name}, увійдіть щоб продовжити!`);
                }
                if (response.data.error){
                    setError(response.data.error);
                }
            })
            .catch(error => {
                const keysArray = Object.values(error.response.data)[0];
                setError(keysArray[0])
            });
        }
        else {
            setError('паролі не співпадають!')
        }
      };

  return (
    <div className="modal">
        <div className="modal-content-reg">
            <div className='model-div'>
                <h2>Реєстрація</h2>
                <span className="close" onClick={registrationModal}>
                &times;
                </span>
            </div>
            <form className='login-form'>
                <label>
                <input 
                    type="text"
                    placeholder="Введіть логін" 
                    autoComplete="username"
                    onChange={(e) => setName(e.target.value)}/>
                </label>
                <label>
                <input 
                    type="password"
                    placeholder="Введіть пароль" 
                    autoComplete="current-password"
                    onChange={(e) => setPass1(e.target.value)}/>
                </label>
                <label>
                <input 
                    type="password"
                    placeholder="Повторіть пароль" 
                    autoComplete="current-password"
                    onChange={(e) => setPass2(e.target.value)}/>
                </label>
                <label>
                <input 
                    type="text"
                    placeholder="email"
                    autoComplete="email" 
                    onChange={(e) => setEmail(e.target.value)}/>
                </label>
                {/* Помилка для паролів */}
                {error && <div style={{color:'red'}}>{error}</div>}
                <button 
                    className='button-form' 
                    type="submit"
                    onClick={handleFormSubmit}
                >Реєстрація</button>
                {/* Спроба вдала */}
                {restpons && <div style={{color:'#05A900'}}>{restpons}</div>}
                <p>Вже є акаунт?</p>
                <button className='button' onClick={toggleModal}>
                Увійти
                </button>
            </form>
        </div>
    </div>
  );
};

export default RegistrationModal;
