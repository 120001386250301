import React, { useRef, useState, useContext, useEffect } from 'react'
import { axiosUrl } from '../api/axios';
import AuthContext from '../data/AuthContext';
import { DataContext } from '../data/DataContext';
import Smollplayer from '../Components/SmollPlayer/Smollplayer';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { FaPlay, FaPause } from 'react-icons/fa';
import './index.scss';

const Cabinet = () => {

  const { user, userProfile, userProf, userDeleteTracks } = useContext(AuthContext);
  const {isPlaying, setIsPlaying, corectTrack, setCorectTrack } = useContext(DataContext)
  const fileInputRef = useRef(null);
  const [sound, setSound] = useState([]);

  const handleFileChange = async (e) => {
    const formData = new FormData();
    formData.append('avatar', e.target.files[0]);
    formData.append('user', user.user_id);

    await axiosUrl.put(`cabinet/${user.user_id}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      userProfile();
      
    })
    .catch(error => {
        console.error('Error uploading photo:', error);
    });
    window.location.reload();
  };

  useEffect (() => {
    if (userProf) {
      const soundFiles = userProf.favorite_songs.map(test => test.audio_file);
      setSound(soundFiles)
    }
  }, [userProf]) 

  const handleUpload = () => {
    fileInputRef.current.click();
  };

  const delTracks = (num) => {
    userDeleteTracks(num)
}
  

  return (
    <main className='cabinet'>
          <h2 className='cab_h'>
            Особистий кабінет
          </h2>
          <header className='cab_header'>
          {userProf && (
            userProf.avatar === null ? (
              <div className='avatar pad1'>
                <FontAwesomeIcon icon={faCircleUser} size='2xl' />              
                <button className='no_avatar' onClick={() => handleUpload()}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <input ref={fileInputRef} style={{ display: 'none' }} type="file" onChange={handleFileChange} />
              </div>
            ) : (
              <div className='avatar'>
                <img className='avatar_img' src={userProf.avatar} alt="" />
                <button className='add_avatar' onClick={() => handleUpload()}>
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <input ref={fileInputRef} style={{ display: 'none' }} type="file" onChange={handleFileChange} />
              </div>
            )
          )}
          {user && 
             <div className='cab_p'>
              <h2>Користувач: {user.username}</h2>
              <h4>Остання активність: { user.last_login }</h4>
              {userProf && (userProf.favorite_songs.length !== 0 ? (<h4>Кількість треків: { userProf.favorite_songs.length }</h4>)
               : (<></>))}
            </div>  }
          </header>
          <main className='cabinet_main'>
            <div className='last_action'>Список улюблених треків:</div>
            <ul>
              {userProf && (userProf.favorite_songs.length === 0 ? (
                <div className='data_title'>Список улюблених треків пустий!</div>
              ) : (
                (userProf.favorite_songs.map((songs) => (
                  <li className='soung_sart' key={songs.id}>
                    {corectTrack === songs.audio_file ? 
                      (isPlaying ? 
                          <FaPause className='play' onClick={() => { setIsPlaying(false)}}/> :
                          <FaPlay className='play' onClick={() => {setCorectTrack(songs.audio_file); setIsPlaying(true)}}/>                           
                      ) : <FaPlay className='play' onClick={() => {setCorectTrack(songs.audio_file); setIsPlaying(true)}}/> }
                    <div className='soung_title'>{songs.title}</div>
                    <div className='data_soung'>{songs.date_request}</div>
                    <div className='sougn_button'>
                    <button className='faMinus' onClick={() => delTracks(songs.id)}>
                        <FontAwesomeIcon icon={faMinus} />
                    </button>                  
                    </div>
                  </li>
                )
              ))
              ))}
            </ul>
          </main> 
          {corectTrack && <Smollplayer src={''} soundList={sound} />} 
    </main>

  )
}

export default Cabinet