import React, { useContext, useState, useEffect } from 'react'
import { axiosUrl } from '../../api/axios';
import AuthContext from '../../data/AuthContext';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp, faPencil, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import './index.css'

const Coments = ({artist}) => {

    const { user } = useContext(AuthContext);
    const [isContentValid, setIsContentValid] = useState(true);
    const [coments, setComents] = useState();
    const [liked, setLiked] = useState(false);
    const [notLogin, setNotLogin] = useState(false);
    const [options, setOptions] = useState(null);
    const [edit, setEdit] = useState(false);
    const [editCommentId, setEditCommentId] = useState(null);
    const [editContent, setEditContent] = useState('');

    const addLike = (comentId) => {
        axiosUrl.post(`addLike/`, {
            comentId:comentId,
            userId:user.user_id,
        })
            .then(response => {
                setLiked(true)
                console.log(response.data.results)
            })
            .catch(error => {
                console.log(error)
            });
    };
    const addDislike = (comentId) => {
        axiosUrl.post(`addDisLike/`, {
            comentId:comentId,
            userId:user.user_id,
        })
            .then(response => {
                setLiked(true)
                console.log(response.data.results)
            })
            .catch(error => {
                console.log(error)
            });
    };

    useEffect(() => {
        axiosUrl.get(`comentAlbom/${artist}/`)
            .then(response => {
                setComents(response.data.results); 
                setLiked(false)
                setEdit(false)
            })
            .catch(error => {
                console.log(error)
            });
        }, [artist, liked, isContentValid, editCommentId, edit]);

    const publish = (event) => {
        event.preventDefault(); // Запобігає перезавантаженню сторінки
        const formData = new FormData(event.target);
        const content = formData.get('content');

        if (!user) {
            setNotLogin(true);
        }          
        if (content && content.trim() !== "" && user) {
            setIsContentValid(true);
            axiosUrl.post(`comentAlbom/`, {
                content: content,
                user: user,
                artist: artist,
            })
            .then(response => {
                if (response.data.comentRespons){
                    console.log(`Вітаємо ${response.data.comentRespons}`);
                    setEdit(true);
                }
                if (response.data.error){
                    console.log(response.data.error);
                }
            })
            .catch(error => {
                console.log(error)
            }); 
          
        } 
        if (content.trim() === "") {
            setIsContentValid(false);
            console.log("Form data is empty!");
        }
    }

    const handleEdit = (coment) => {
        setOptions(coment.id);
        setEditContent(coment.description);
      };
    
      const handleSave = (event, comentId) => {
        event.preventDefault(); // Запобігає перезавантаженню сторінки
        const formData = new FormData(event.target);
        const content = formData.get('content');
        if (content && content.trim() !== "" && user) {
            setIsContentValid(true);
            axiosUrl.put(`comentAlbom/`, {
                content: content,
                user: user,
                comentId: comentId,
            })
            .then(response => {
                if (response.data.comentRespons){
                    console.log(response.data.comentRespons);
                    setEdit(true);
                }
                if (response.data.error){
                    console.log(response.data.error);
                }
            })
            .catch(error => {
                console.log(error)
            }); 
          
        } 
        setOptions(false)
        setEditCommentId(null);
        setEditContent('');
      };

      const deleteComent = (comentId) => {
        console.log(comentId)
        axiosUrl.delete(`comentAlbom/`, {
            data: { id: comentId },
        })
        .then(response => {
            if (response.data.comentRespons){
                console.log(response.data.comentRespons);
                setEdit(true);
            }
            if (response.data.error){
                console.log(response.data.error);
            }
        })
        .catch(error => {
            console.log(error)
        }); 
      }

  return (
    <div className='сoments_head'>
        <div className='coments_title'>Відгуки</div>
        <form onSubmit={publish}>
            <textarea className={`content ${!isContentValid ? 'content_error' : ''}`} name='content' rows={4} cols={40} />
            {!isContentValid && (
                    <div className="error-message">Поле обов'язкове для заповнення</div>
                )}
            {notLogin && (
                <div className="error-message">Увійдіть або зареєструйтесь, щоб залишити комертар!</div>
            )}
            <button type="submit" className="content_button" value="submit">Залишити відгук</button>
        </form>

        {coments && [...coments].reverse().map((coment) => (
            <div className='coments_body' key={coment.id}>
                <div className='coments_user'>
                    <div className='coments_user_name'>

                        <h3>{coment.user}</h3>
                    </div>                    
                    <img className='coments_ava' src={coment.avatar} alt='ava'/>                
                </div>
                <div className='coments_content'>
                    <div className='coment_time'>
                        <h3>{coment.time}</h3>
                        {!options && user && user.username === coment.user &&
                            <FontAwesomeIcon icon={faXmarkCircle} style={{color:'#CD5C5C', margin:'5px 0 5px 10px', fontSize:'17px'}} onClick={() => deleteComent(coment.id)}/>
                        }
                    </div>
                    <div className='coment'>
                        <div className={options ? 'discription' : 'coment_discription'}>
                            {options === coment.id ?
                                <form onSubmit={(e) => handleSave(e, coment.id)}>
                                    <textarea className={`content ${!isContentValid ? 'content_error' : ''}`} name='content' rows={4} value={editContent} onChange={(e) => setEditContent(e.target.value)} />
                                    <div className='put_button'>
                                        <button type="submit" className="content_button" value="save">Зберегти</button>
                                        <button className="content_button cancel_button" onClick={() => setOptions(false)}>Скасувати</button>
                                    </div>
                                </form> : <p>{coment.description}</p>
                            }
                            {!options && user && user.username === coment.user &&
                                <FontAwesomeIcon 
                                    icon={faPencil} 
                                    style={{ color: "#222222", width: "35px" }} 
                                    onClick={() => handleEdit(coment)} />
                            }
                        </div>
                    </div>
                    <div className='coments_likes'>
                        <div className='coment_time coment_like_down coments_likes'>
                            {user != null ? <FontAwesomeIcon icon={faThumbsDown} onClick={() => addDislike(coment.id)}size='xl'/> : 
                                <FontAwesomeIcon icon={faThumbsDown}size='xl'/>}
                            
                            <p className='coment_like_value'>{coment.dislike}</p>
                        </div>
                        <div className='coment_time coment_like_up coments_likes'>
                            {user != null ? <FontAwesomeIcon icon={faThumbsUp} onClick={() => addLike(coment.id)} size='xl'/> : 
                                <FontAwesomeIcon icon={faThumbsUp} size='xl'/>}
                            <p className='coment_like_value'>{coment.like}</p>
                        </div>                    
                    </div>
                </div>
            </div>            
        ))}
    </div>
  )
}

export default Coments