import React, {useState, useEffect, useContext} from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { axiosUrl } from '../api/axios';
import { Pagination, PaginationItem, TextField, Link } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../data/AuthContext';

import './index.scss'

const Radio = () => {
  const location = useLocation(); // Access the current location
  const navigate = useNavigate(); // Access the navigation function
  const [urlRadio, setUrlRadio] = useState(null);
  const [titleRadio, setTitleRadio] = useState(null)
  const [radios, setRadios] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageQty, setPageQty] = useState(0);
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(true);
  const { authToken, updateToken, user } = useContext(AuthContext)


  const changePage = (newPage, search) => {
    setPage(newPage);
    const newPath = `/radio/?page=${newPage}&search=${search}`; // Генеруємо новий шлях
    navigate(newPath); // Змінюємо шлях за допомогою navigate
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const initialPage = parseInt(searchParams.get('page')) || 1;
    setPage(initialPage);

    const initialSearch = searchParams.get('search') || '';
    setSearch(initialSearch);
  }, [location.search]);

  useEffect(() => {
    // axios.get(`${URL_Base}radio/?page=${page}&search=${search}`, {'withCredentials': true })
    axiosUrl.get(`radio/?page=${page}&search=${search}`, {
      headers: {
        Authorization:'Bearer '+String(authToken)
      },
    })
      .then(response => {
        setRadios(response.data);
        setLoading(false); // Позначаємо, що завантаження завершено
        setPageQty(response.data.total_pages);
      })
      .catch(error => {
        if (error.response.data.detail) {
          setErr(`Не зареєстрований користувач не може переглядати даний контент!\n Увійдіть у свій акаунт або зареєструйтесь!`)
          setPage(1);
          setLoading(false);      
        }
        if (user) {
          updateToken();
          window.location.reload();          
        } // Позначаємо, що завантаження завершено, навіть якщо сталася помилка
      })
  }, [ page, search, loading, authToken, updateToken, user]); 

  const playRadio = (url) => {
    setUrlRadio(url);
  };
  const addTitleRadio = (url) => {
    setTitleRadio(url);
  };

  if (loading) {
    // Відображення загрузки, поки дані завантажуються
    return <main className='Artists'>
      <div>Loading...</div>
    </main>;
  }

  return (
    <main className='Radio'>
      <div className='SearchRadio'>
        <TextField 
          fullWidth
          label='Пошук у Radio'
          value={search}
          onChange={(event) => setSearch(event.target.value) & changePage(1, event.target.value)}
          size="small"
          margin="dense"
        />
      </div>
       {err && (
          <h2 style={{'marginTop':'30px', 'alignSelf':'center', 'color':'#9E3534'}}>
            {err.split('\n').map((item, index) => (
              <div style={{'text-align':'center'}} key={index}>{item}</div>
            ))}
            </h2>
        )}
        
        {!err && radios.results && (
          <ul>
            {radios.results.map((radio) => (
              <li key={radio.id}>
                <div onClick={() => {
                  playRadio(radio.url);
                  addTitleRadio(radio.title);
                }}>
                  <img className='Radio_Img' key={radio.id} src={radio.photo} alt={radio.id} />
                  <p>{radio.title}</p>
                </div>
              </li>
            ))}
          </ul>
        )}

       
      
      <div className='RadioDiv'></div>
      <div className='Pagination'>
        { !!pageQty && (
          <Pagination 
            count={pageQty}
            page={page}
            onChange={(_, num) => changePage(num, search)}
            showFirstButton
            showLastButton
            size="small"
            renderItem={(item) => (
              <PaginationItem 
                  component={Link}
                  {...item}
              />
            )}
          />
        )}
      </div>

      <div className='player'>  
        <p>{titleRadio}</p>
        {<ReactAudioPlayer src={urlRadio} 
            autoPlay={true}
            controls 
            controlsList={
                'nodownload'
            }
            style={{ 
                backgroundColor: '#F1F3F4', 
                width: '100%',
            }}
        />}
      </div>
    </main>
  )
}

export default Radio