import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom'
import LoginModal from '../Login/Login';
import AuthContext from '../../data/AuthContext';
import { DataContext } from '../../data/DataContext';
import { TextField, ThemeProvider, createTheme } from '@mui/material';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faUser} from '@fortawesome/free-solid-svg-icons';
import { FaBars, FaArrowRight, FaCartArrowDown } from 'react-icons/fa';
import Basket from '../Basket/Basket';

import './index.scss'

const Nav = () => {

  let { user, logoutUser, userProf, userProfile, isBuy, setIsBuy } = useContext(AuthContext);
  let { serachData, setSearchTrue, isOpenBasket, setIsOpenBasket } = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(false);
  const [dicplay, setDispley] = useState('flax');

  const [ searchSee, setSerchSee ] = useState(true);

  const theme = createTheme({
    palette: {
      text: {
        primary: "#F0F8FF",
      },
    },
  });

  useEffect (() => {
    userProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBuy, isOpenBasket]);

  const toggleMenu = () => {
    if (isOpen) {
      setIsOpen(false);
      setDispley('none')
    } else {
      setDispley('flex')
      setIsOpen(true);
    }

    console.log(isOpen)
};

  return (
    <nav className='Nav'>
        <div className='Logo'>
          <a href='/'>
            <img type= "image/png" src={`${process.env.PUBLIC_URL}/static/logo192.png`} alt="Logo1" />
          </a>
        </div>
        {searchSee &&
          <div className='searchNav'>
            <ThemeProvider theme={theme}>
              <TextField 
                fullWidth
                label='Пошук'
                //value={search}
                // onChange={(event) => setSearch(event.target.value) & changePage(1, event.target.value)}
                onChange={(event) => serachData(event.target.value) & setSearchTrue(true)}
                size="small"
                color="warning"
              />  
          </ThemeProvider>
          </div>        
        }
        {!isOpen && <Link className='hamburger' onClick={toggleMenu}><FaBars /></Link>}
        <ul className='NavUl' style={{display: `${dicplay}`}}>
          {isOpen && <Link className='hamburger' onClick={toggleMenu}><FaArrowRight /></Link>}
          <Link onClick={() => {setSerchSee(true); setIsBuy(false)}} to='/'>Home</Link>
          <Link onClick={() => {setSerchSee(false); setIsBuy(false)}} to='/artists'>Artists</Link>
          <Link onClick={() => setSerchSee(false)} to='/news'>News</Link>
          <Link onClick={() => setSerchSee(false)} to='/radio'>Radio</Link>
          <Link onClick={() => setSerchSee(false)} to='/about'>About</Link>
          {userProf && userProf.favorite_alboms.length !== 0 ? <Link className='basketNav' onClick={() => {setIsOpenBasket(true)}}><FaCartArrowDown className='Cart'/><p className='CartP'>{userProf.favorite_alboms.length}</p></Link> : 
          <Link className='basketNav'><FaCartArrowDown className='Cart'/></Link>
          } 
          {isOpenBasket && <Basket tracks={userProf.favorite_alboms}/>}
                    
          {user === null && <LoginModal />}
          {user && userProf === null && (
            <>
              <Link className='userName' to='/cabinet'>{user.username}<FontAwesomeIcon className='faUser' icon={faUser} size='xl'/></Link>
              <Link onClick={logoutUser}><FontAwesomeIcon icon={faRightFromBracket} size='xl'/></Link>
            </>
          )}
          {user && userProf != null && userProf.avatar === null && (
            <>
              <Link className='userName' to='/cabinet'>{user.username}<FontAwesomeIcon className='faUser' icon={faUser} size='xl'/></Link>
              <Link onClick={logoutUser}><FontAwesomeIcon icon={faRightFromBracket} size='xl'/></Link>
            </>
          )}
          {user && userProf != null && userProf.avatar != null && ( 
            <>
              <Link className='userName' to='/cabinet'>{user.username}<img className='avatarNav' src={userProf.avatar} alt={user.username} /></Link>
              <Link onClick={logoutUser}><FontAwesomeIcon icon={faRightFromBracket} size='xl'/></Link>
            </>
          )} 
        </ul>
    </nav>
  )
}

export default Nav