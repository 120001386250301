import React, { useState, useEffect } from 'react';
import { axiosUrl } from '../api/axios';
import { Link } from 'react-router-dom';

import './index.scss'

const Artists = () => {

  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosUrl.get(`artists/`)
      .then(response => {
        setArtists(response.data);
        setLoading(false); // Позначаємо, що завантаження завершено
      })
      .catch(error => {
        setLoading(false); // Позначаємо, що завантаження завершено, навіть якщо сталася помилка
      });
  }, []);

  if (loading) {
    // Відображення загрузки, поки дані завантажуються
    return <main className='Artists'>
      <div>Loading...</div>
    </main>;
  }

  return (
    <main className='Artists'>
      <ul>
        {artists.results.map((artist) => (
          <li className='Artist_Cart' key={artist.id}>
            <Link to={`/artists/${artist.id}`} key={artist.id}>
            <img className='Artist_Cart_Img' key={artist.id} src={artist.photo} alt={artist.id} />
            <div>{artist.title}</div>
            </Link>
          </li>
        ))}
      </ul>
    </main>
  );
};

export default Artists