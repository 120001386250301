import React, {useContext, useState, useEffect, useRef } from 'react'
import { FaVolumeUp, FaRetweet, FaInfinity } from 'react-icons/fa';
import { DataContext } from '../../data/DataContext';
import Slider from './slider/Slider';
import SliderVolume from './slider/SliderVolume';
import ControlPanel from './controls/ControlPanel';
import './index.css';

const Smollplayer = ({src = '', track, soundList }) => {

    const audioPlayer = useRef(null);
    const [number, setNumber] = useState(0);
    const [title, setTitle] = useState();
    const { isPlaying, setIsPlaying, setCorectTrack, corectTrack } = useContext(DataContext);
    const [duration, setDuration] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [volume, setVolume] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [showSlider, setShowSlider] = useState(false);
    const [timer, setTimer] = useState(null);
    const [isRepeat, setIsRepead] = useState(false);
    const [isArbitrary, setIsArbitrary] = useState(false);
    const randomList =  Math.floor(Math.random() * soundList.length)
 
    useEffect(() => {
        if (!isPlaying) {
            pause();
            setTitle(titleTrack(corectTrack))
        } 
        if (isPlaying) {
            play();
            // console.log(isPlaying)
            setTitle(titleTrack(corectTrack))
        }

        if (soundList.includes(track)) {
            setNumber(soundList.indexOf(track));
            setCorectTrack(track);
            setTitle(titleTrack(corectTrack))
        }
    }, [isPlaying, track, corectTrack]);

    const pause = () => {
        if (audioPlayer.current) {
            audioPlayer.current.pause();
            setIsPlaying(false)
        }
    }

    const play = () => {
        if (audioPlayer.current) {
            audioPlayer.current.play()
            setIsPlaying(true)
            setVolume(audioPlayer.current.volume = 0.5)
        }
    }

    const titleTrack = (track) => {
        const fileName = track.match(/([^\/]+)(?=\.\w+$)/)[0];
        return fileName.replace(/_/g, ' ')
    }

    const playPreviousTrack = (num) => {
        if (number > 0){
            setNumber(num - 1);
            setCorectTrack(soundList[num - 1]);
            setTitle(titleTrack(soundList[num - 1]))
        }
    };

    const playNextTrack = (num) => {
        if (number < soundList.length - 1){
            setNumber(num + 1);
            setCorectTrack(soundList[num + 1]);
            setTitle(titleTrack(soundList[num + 1]))
        } 
    };

    const onChangeCurrentTime = (e) =>{
        audioPlayer.current.currentTime = (audioPlayer.current.duration / 100) * e.target.value;
        setPercentage(e.target.value);
    }

    const onChangeVolume = (e) =>{
        const currentVolume = audioPlayer.current.volume = e.target.value / 100;  // volume
        setVolume(currentVolume * 100)
    }

    const handleMouseEnter = () => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }
        setShowSlider(true);
    };

    const handleMouseLeave = () => {
        const newTimer = setTimeout(() => {
            setShowSlider(false);
        }, 500);
        setTimer(newTimer);
    };

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    const repeatTrack = () => {
        if (!isRepeat) {
            setIsRepead(true)
            if (isArbitrary) {
                setIsArbitrary(false)
            }
        } else {
            setIsRepead(false)
        }
    }

    const arbitraryTrack = () => {
        if (!isArbitrary) {
            setIsArbitrary(true)
            if (isRepeat) {
                setIsRepead(false)
            }
        } else {
            setIsArbitrary(false)
        }
    }

    // replay and random control function
    const getCurrDuration = (e) => {
        const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
        const time = e.currentTarget.currentTime

        if (e.currentTarget.currentTime === e.currentTarget.duration) {
            if (isRepeat) {
                setIsPlaying(false)
                play();
            }
            if (!isRepeat && soundList.indexOf(corectTrack) !== soundList.length - 1  && !isArbitrary) {
                playNextTrack(soundList.indexOf(corectTrack))
                setIsPlaying(true)
            }
            if (!isRepeat && isArbitrary) {
                setCorectTrack(soundList[randomList])
                if (randomList === soundList.indexOf(corectTrack) && soundList.indexOf(corectTrack) !== soundList.length) {
                    if (soundList[randomList + 1]) {
                        setCorectTrack(soundList[randomList + 1])
                        setIsPlaying(true)
                    } else {
                        setCorectTrack(soundList[0])
                        setIsPlaying(true)
                    }
                }
                setIsPlaying(true)
            }
            if (soundList.indexOf(corectTrack) === soundList.length - 1 && !isRepeat && !isArbitrary) {
                setNumber(0)
                setCorectTrack(soundList[0]);
            }
        }
    
        setPercentage(+(parseFloat(percent)))
        setCurrentTime(time.toFixed(2))
      }

  return (
    <div className='smollPlayer'>
        <div className='control_player'>
            <div className='doby_control'>
                <img className='Track_Img' src={src} />
                <p className='title_smollplayer'>{title}</p>
            </div>
            <div className='controls'>
                <img className='button_smollplayer' type= "image/png" src={`${process.env.PUBLIC_URL}/static/left.png`} alt="buttom left" onClick={() => playPreviousTrack(number)} />
                {!isPlaying ?  <img className='button_playPlayer' type= "image/png" src={`${process.env.PUBLIC_URL}/static/play.png`} alt="buttom play" onClick={() => play()} /> : 
                <img className='button_playPlayer' type= "image/png" src={`${process.env.PUBLIC_URL}/static/pause.png`} alt="buttom pause" onClick={() => pause()} />}     
                <img className='button_smollplayer' type= "image/png" src={`${process.env.PUBLIC_URL}/static/right.png`} alt="buttom right" onClick={() => playNextTrack(number)} />                     
            </div>
            <div className='volume_button' 
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}>
                {!isRepeat ? <FaRetweet onClick={repeatTrack} style={{color:"#7F8283"}}/> : <FaRetweet onClick={repeatTrack} />}
                {!isArbitrary ? <FaInfinity onClick={arbitraryTrack} style={{color:"#7F8283"}}/> : <FaInfinity onClick={arbitraryTrack} />}
                <FaVolumeUp />
                {showSlider && 
                <div className='slayder_volume'>
                    <SliderVolume percentage={volume} onChange={onChangeVolume} />
                </div>}
            </div>  
        </div>

        <div className='input_player slayder_time'>
            <Slider percentage={percentage} onChange={onChangeCurrentTime} />
            <ControlPanel
                duration={duration}
                currentTime={currentTime}
            />
        </div>



        <audio 
            onTimeUpdate={getCurrDuration}
            onLoadedData={(e) => {
                setDuration(e.currentTarget.duration.toFixed(2))
                }}
            ref={audioPlayer}
            autoPlay={true} 
            src={corectTrack} 
            // controls 
            style={{ 
                backgroundColor: '#F1F3F4', 
                width: '100%',
            }}></audio>
    </div>
  )
}

export default Smollplayer;