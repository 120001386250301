import React, { useState, useEffect } from 'react';
import { axiosUrl } from '../api/axios';
import { Link } from 'react-router-dom';

import './index.scss'

const News = () => {

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosUrl.get(`news/`)
      .then(response => {
        setNews(response.data);
        setLoading(false); // Позначаємо, що завантаження завершено
      })
      .catch(error => {
        setLoading(false); // Позначаємо, що завантаження завершено, навіть якщо сталася помилка
      });
  }, []);

  if (loading) {
    // Відображення загрузки, поки дані завантажуються
    return <main className='Artists'>
      <div>Loading...</div>
    </main>;
  }

  return (
    <main className='News'>
      <ul>
        {news.articles.map((article) => (
          article.title !== null && article.urlToImage !== null && article.author !== null && (
            <li className='Artist_Cart' key={article.id + article.url}>
              <div className='NewTitle'>{article.title}</div>
              <Link to={article.url} key={article.id}>
              <img className='Artist_Cart_Img' key={article.id} src={article.urlToImage} alt={article.id} />
              </Link>
            </li>
          )
        ))}
      </ul>
    </main>
  );
};

export default News