import React, { useState, useContext } from 'react'
import { DataContext } from '../../data/DataContext';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import './index.css';

const Heard = ({idTrack}) => {

    const [hearts, setHearts] = useState([]);
    const { ratingAlbom } = useContext(DataContext);

    const handleClick = (id) => {
      const newHeart = { id: Date.now() };
      setHearts((prevHearts) => ({
        ...prevHearts,
        [id]: [...(prevHearts[id] || []), newHeart],
      }));
  
      // Видалити сердечко через 2 секунди
      setTimeout(() => {
        setHearts((prevHearts) => ({
          ...prevHearts,
          [id]: prevHearts[id].filter((heart) => heart.id !== newHeart.id),
        }));
      }, 2000);
    };

  return (
    <button className='Heart' onClick={() => {ratingAlbom(idTrack); handleClick(idTrack)}}><FontAwesomeIcon icon={faHeart} size='2xl'/>
    {hearts[idTrack] &&
      hearts[idTrack].map((heart) => (
        <div key={heart.id} className='heart'>
          ❤️
        </div>
      ))}
      </button>
  )
}

export default Heard