import Nav from "./Components/Nav/Nav";
import Footer from "./Components/Footer/Footer";
import Radio from "./Pages/Radio";
import Home from "./Pages/Home";
import Artists from "./Pages/Artists";
import Tracks from "./Pages/Tracks";
import ArtistDetails from "./Pages/ArtistDetails";
import News from "./Pages/News";
import About from "./Pages/About";
import Missing from "./Pages/Missing";
import Cabinet from "./Pages/Cabinet";
import { Route, Routes } from 'react-router-dom';


function App() {
  // const location = useLocation();
  // const { pathname } = location;

  return (
    <div className="App">
      {/* <Header /> */}
      {/* {pathname !== `/cabinet` && <Nav />} */}
      <Nav />
      <Routes>
        <Route path="/cabinet" element={<Cabinet />} />
        <Route path="/" element={<Home />} />
        <Route path="/artists" element={<Artists />} />
        <Route path="/artists/:artistId" element={<ArtistDetails />} />
        <Route path="/albom/:albomId" element={<Tracks />} />
        <Route path="/news" element={<News />} />
        <Route path="/radio" element={<Radio />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<Missing />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
