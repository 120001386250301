import { useState, useRef, useEffect } from 'react'
import './slider.css'
import './thumb.css'

function SliderVolume({ percentage, onChange }) {
  const [position, setPosition] = useState(50)
  const [marginLeft, setMarginLeft] = useState(-10)

  const rangeRef = useRef()
  const thumbRef = useRef()

  useEffect(() => {
    const thumbWidth = thumbRef.current.getBoundingClientRect().width
    const centerThumb = (thumbWidth / 100) * percentage * -1
    setPosition(percentage)
    setMarginLeft(centerThumb)
  }, [percentage])

  return (
    <div className='slider-container'>
      <div
        className='progress-bar-cover'
        style={{
          width: `${position}%`
        }}
      ></div>
      <div
        className='thumb'
        ref={thumbRef}
        style={{
          left: `${position}%`,
          marginLeft: `${marginLeft}px`
        }}
      ></div>
      <input
        type='range'
        value={position}
        ref={rangeRef}
        step='0.01'
        className='range'
        onChange={onChange}
      />
    </div>
  )
}

export default SliderVolume
