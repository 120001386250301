import React from 'react'

import './index.scss'

const Footer = () => {
  const today = new Date();

  return (
    <footer className='Footer'>
      <p>Copyrigth &copy; {today.getFullYear()}</p>
      <p>Smollcorporation &copy;</p>
    </footer>
  )
}

export default Footer