import React, { useRef, useState, useContext } from 'react';
import RegisrationModal from '../Regisration/Registration';
import AuthContext from '../../data/AuthContext';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import './index.scss'
import { Link } from 'react-router-dom';


const LoginModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwr] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [type, setType] = useState("password")


  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleRegistrationModal = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    setIsRegistrationModalOpen(!isRegistrationModalOpen);
  };
  const registrationModal = (e) => {
    e.preventDefault();
    setIsRegistrationModalOpen(!isRegistrationModalOpen);
  };

  const { loginUser, err } = useContext(AuthContext);

  const changeType = () => {
    if (type === "password") {
      setType("text")
    } else {
      setType("password")
    }
  }

  return (
    <>
    <Link onClick={toggleModal}>Вхід</Link>
      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className='model-div'>
              <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
              <h2>Вхід</h2>
              <span className="close" onClick={toggleModal}>
              &times;
              </span>
            </div>
            <form className='login-form' onSubmit={loginUser}>
              <label>
                <input 
                  type="text"
                  placeholder="Логін:"
                  id='username'
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required 
                  autoComplete="off"
                />
              </label>
              <label>
                <input 
                  type={type}
                  placeholder="Пароль:"
                  id='password'
                  onChange={(e) => setPwr(e.target.value)}
                  value={pwd}
                  required
                  autoComplete="current-password"
                />
              </label>
              <button type='button' className='button' onClick={changeType}>
                {type === "password" ? <FontAwesomeIcon icon={faEyeSlash} size='xl'/> : <FontAwesomeIcon icon={faEye} size='xl'/>}
              </button>
              {err && <div style={{color:'red'}}>{err}</div>}
              <button 
                className='button-form' 
                type="submit"
                >Вхід</button>
              <br/>
              <br/>
              <p>Потрібний акаунт?</p>
              <button className='button' onClick={toggleRegistrationModal}>
                Зареєструватися
              </button>
            </form>
          </div>
        </div>
      )}
      {isRegistrationModalOpen && <RegisrationModal registrationModal={registrationModal} toggleModal={toggleRegistrationModal} />}
    </>
  );
};

export default LoginModal;
