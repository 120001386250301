import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../data/DataContext';
import AuthContext from '../../data/AuthContext';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FaTrash } from 'react-icons/fa';
import './index.css'

const Basket = () => {
  const { setIsOpenBasket } = useContext(DataContext)
  const { userDeleteAlboms, setIsBuy, userProf, userProfile } = useContext(AuthContext);

  const [totalPrice, setTotalPrice] = useState([]);

  const totalSum = () => {
    setTotalPrice([...userProf.favorite_alboms.map((track) => (track.total_price))])
  }
  const sum = totalPrice.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  
  useEffect (() => {
    totalSum();
  // eslint-disable-next-line   
  }, [userProf]);  


      
  if (userProf.favorite_alboms && userProf.favorite_alboms.length !== 0) {
    return (
      <div className='basket'>
        <div className='basketModal'>
          <div className='basketModalHead'>
            <h3>
              Кошик
            </h3>
            <FontAwesomeIcon className='modalCloseBtn' icon={faXmarkCircle} style={{color:'#CD5C5C', margin:'5px 0 5px 10px', fontSize:'26px'}} onClick={() => {setIsOpenBasket(false); setIsBuy(false)}}/>         
          </div>
          <ul className='basketBody'>
            {userProf.favorite_alboms.map((track) => (
              <li className='basketAlbom' key={track.id}>
                <p>
                  <img className='basketImg' src={track.photo} alt={'img' + track.title}/>
                </p>
                <div className='basketDescription'>{track.title}</div>
                <div className='albomFooter'>
                  <div className='albomPrice basketDescription'>{track.total_price} $</div>
                  <FaTrash className='deleteBtn basketDescription' onClick={() => {userDeleteAlboms(track.id); setIsBuy(false); userProfile() }}/>               
                </div>
          
              </li>              
            ))}
          </ul>
          <div className='totalPrice'>
            <p className='priceSum basketDescription'>{sum.toFixed(2)} $</p>
            <button className='finalBtn'>Оформити замовлення</button>
          </div>
        </div>
      </div>
    )    
  } 
  if (userProf.favorite_alboms && userProf.favorite_alboms.length === 0) {
    return (
      <div className='basket'>
        <div className='basketModal'>
          <div className='basketModalHead'>
            <h3>
              Кошик
            </h3>
            <button className='modalCloseBtn' onClick={() => {setIsOpenBasket(false); setIsBuy(false); userProfile()}}>x</button>          
          </div>
         <h3>Ваший Кошик порожній!</h3>
        </div>
      </div>)
  }

}

export default Basket