import React, { useState, useEffect } from 'react';
import { axiosUrl } from '../api/axios';
import { useParams, Link as NavLink } from 'react-router-dom';
import { Pagination, PaginationItem, Link } from '@mui/material';
import Heard from '../Components/Heard/Heard';
import Coments from '../Components/Coments/Coments';

const ArtistDetails = () => {
    const { artistId, page: initialPage } = useParams();
    const [alboms, setAlboms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(initialPage ? parseInt(initialPage, 10) : 1);
    const [displayData, setDisplayData] = useState([]);
    const perPage = 10;

    // При зміні сторінки
    const handleChangePage = (event, value) => {
      setPage(value);

      // Зберігаємо поточну сторінку в локальному сховищі
      localStorage.setItem('currentPage', value.toString());
    };

    useEffect(() => {
      axiosUrl.get(`artist/${artistId}/`)
          .then(response => {
            setAlboms(response.data.results);
            setLoading(false); // Позначаємо, що завантаження завершено   
          })
          .catch(error => {
            setLoading(false); // Позначаємо, що завантаження завершено, навіть якщо сталася помилка
          });

        // Отримуємо збережену сторінку з локального сховища
      const savedPage = localStorage.getItem('currentPage');
      
      // Встановлюємо поточну сторінку на збережену сторінку або 1 за замовчуванням
      setPage(savedPage ? parseInt(savedPage, 10) : 1);
      }, [artistId]);
       
      useEffect(() => {
        if (alboms.length > 0) {
          setDisplayData(alboms.slice((page - 1) * perPage, page * perPage))
        }
      }, [page, alboms]);
      
      if (loading) {
        // Відображення загрузки, поки дані завантажуються
        return <main className='Home'>
          <div>Loading...</div>
        </main>;
      }
      
      const pageCount = Math.ceil(alboms.length / perPage);

      return (
        <main className='Home ArtistDetails'>
        {alboms.length !== 0 ? (
        <>
          <article className='Artist_Cart'>
            <img className='Artist_Cart_Img' src={alboms[0].artist.photo} alt={alboms[0].artist.id} />
            <div className='Artist_Cart_body'>
              <h1>{alboms[0].artist.title}</h1>
              <h3>Жанр: {alboms[0].artist.genre.title}</h3>
              <h3>Усього альбомів: {alboms.length}</h3>
            </div>
          </article>
          <ul>
            {displayData.map((albom) => (
              <div key={albom.id}>
              <NavLink to={`/albom/${albom.id}/`} key={albom.id}>
                <li className='Sounds_Cart'>
                  <img className='Sounds_Cart_Img' src={albom.photo} alt={albom.title} />
                  <div className='Title'>{albom.title}</div>
                  <div className='Performer'>{albom.artist.title}</div> 
                  {albom.discount > 0 ? (
                    <>
                      <div className='Discount'>-{albom.discount}%</div>
                      <div className='Price'>
                        <div className='OldPrice'>{albom.price}$</div>
                        <div className='TotalPrice'>{albom.total_price}$</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='Price'>
                        <div className='TotalPrice'>{albom.total_price}$</div>
                      </div>
                    </>
                  )}
                </li>
              </NavLink>
              <Heard idTrack={albom.id} />
              </div>
            ))}
          </ul>
          <div className='RadioDiv'></div>
          <div className='Pagination'>
            <Pagination 
              count={pageCount}
              page={page} // Встановлюємо початкову сторінку
              onChange={handleChangePage}
              showFirstButton
              showLastButton
              size="small"
              renderItem={(item) => (
                <PaginationItem 
                  component={Link}
                  {...item}
                />
              )} 
            />
          </div>        
        </>) : (
        <>
          <h3 style={{'text-align':'center', 'margin-top':'20px', 'color': '#161616' }}>У цього артиста відсутні треки!</h3>
        </>)}  

        <Coments artist={artistId}/>


        </main>
      );
    };

export default ArtistDetails;
