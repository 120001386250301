import React from 'react'
import { Link } from 'react-router-dom'

const Missing = () => {
  return (
    <main className='Missing'>
        <div>
            <br/><br/>
            <h2>Сторінки не існує!</h2>
            <p>Ну, це розчаровує.</p><br/>
            <p>
            <Link to='/'>Відвідати домашню сторінку</Link>
            </p>
        </div>
        
    </main>
  )
}

export default Missing